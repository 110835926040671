<template lang="pug">
div.root(:class="root_class")
  span.data_value(v-if="!is_bool" v-model="value") {{ value }}{{ data.unit }}
  span.fake(v-if="is_bool || data == null") 000
  switches(v-if="is_bool && switch_is_bold" :value="value"
           theme="custom" color="white" type-bold="false")
  switches(v-if="is_bool && !switch_is_bold" :value="value"
           theme="custom" color="white")
  div.pending_icon(v-show="data.pending")
    icon(name="plug" scale=0.9)
  div.alarm_icon(v-show="data.on_alarm")
    icon(name="bell" scale=1)
</template>

<script>
import 'vue-awesome/icons/plug'
import 'vue-awesome/icons/bell'
import Icon from 'vue-awesome/components/Icon'
import Switches from 'vue-switches'

export default {
  name: 'Data_widget',
  props: ['data', 'value', 'size'],
  components: {
    'icon': Icon,
    'switches': Switches
  },

  data () {
    return {}
  },

  computed: {
    is_bool () {
      if (!this.data) return false
      return (this.data.entry_type === 'BO')
    },

    switch_is_bold () {
      return this.size === 'big'
    },

    root_class () {
      return this.data.on_alarm ? 'alarm' : ''
    }
  },

  mounted: function () {}
}
</script>


<style lang="scss" scoped>
@import '../assets/colors';
@import '../assets/mixins';

.root {
  position: relative;
}

// .fake {
//   display: none;
// }

.alarm {
  @include infinite-zoomin;
  text-shadow: -1px 0 $light_red, 0 1px $light_red, 1px 0 $light_red, 0 -1px $light_red;
}

.pending_icon,
.alarm_icon {
  color: $red;
  position: absolute;

  svg {
    background-color: $control_border;
    border: 1px solid transparent;
    border-radius: 8px;
  }
}

.alarm_icon {
  color: $white;

  svg {
    background-color: $red_shadow;
  }
}
</style>
