const main_color = 'rgba(255,255,255,.8)'
// const white_shadow = 'rtime.mingba(255,255,255,0.3)'
const red_shadow = 'rgba(255,0,0,0.3)'
// const black = 'rgba(0,0,0,1)'
const black_shadow = 'rgba(0,0,0,0.4)'
// const black_shadow_light = 'rgba(0,0,0,0.2)'
// const red = 'rgba(255,0,0,0.7)'
const transparent = 'rgba(0,0,0,0)'
// const line_color = 'rgba(255,255,255,0.4)'
// const point_color = 'rgba(255,255,255,0.8)'

import { hex_to_rgb, LightenDarkenColor } from '@/utils'


const get_options = function (data, content, period) {
  period = period || 1
  let x_unit = (period >= 3) ? 'day' : 'hour'
  let y_max = 0;
  if (content && content.datasets.length && data.entry_type !== 'BO') {
    for (let point of content.datasets[0].data) {
      if (point.y > y_max) y_max = point.y
    }
    y_max += y_max*.01
  }
  return {
    responsive: true,
    maintainAspectRatio: false,
    events: ['click'],
    legend: {
      display: false,
    },
    // legend: {
    //   display: true,
    //   labels: {
    //     fontFamily: 'OpenSans',
    //     fontColor: black_shadow,
    //     fontSize: 14
    //   }
    // },
    scales: {
      xAxes: [{
        type: 'time',
        ticks: {
          fontColor: black_shadow,
          min: new Date().getTime() - (period * 24 * 3600000),
          max: new Date().getTime(),
        },
        time: {
          unit: x_unit,
          displayFormats: { day: 'ddd D/MM H[h]', hour: 'D/MM H[h]' }
        },
        gridLines: { color: black_shadow, zeroLineColor: black_shadow }
      }],
      yAxes: [
        {
          id: 'main',
          display: data.entry_type !== 'BO',
          position: 'left',
          ticks: (data.entry_type === 'BO') ?
            { min: 0, max: 1.25, fontColor: black_shadow }
            : {
              max: y_max,
              fontColor: black_shadow,
              // Remove floating point values
              callback: function (value, unused_index, unused_values) {
                if (Math.floor(value) === value) return value
              }
            },
          gridLines: { color: black_shadow, zeroLineColor: black_shadow }
        },
        {
          id: 'secondary',
          display: false
        }
      ]
    }
  }
}

const get_main_dataset = function (data, map) {
  return {
    backgroundColor: hex_to_rgb(data.color, '.2'),
    // This is line color
    borderColor: LightenDarkenColor(data.color, -25),
    data: map,
    // label: `${data.name} (${data.unit})`,
    label: `${data.unit}`,
    fill: data.entry_type === 'BO',
    borderWidth: 4,
    lineTension: data.entry_type === 'BO' ? 0 : 0.2,
    pointBackgroundColor: hex_to_rgb(data.color, '.3'),
    pointBorderColor: data.color,
    pointRadius: 2,
    showLine: true,
    yAxisID: 'main'
  }
}

const get_dataset = function (map) {
  return {
    backgroundColor: black_shadow,
    borderColor: transparent,
    borderWidth: 0,
    data: map,
    fill: true,
    lineTension: 0,
    pointRadius: 0,
    showLine: true,
    yAxisID: 'secondary'
  }
}
export { get_options, main_color, get_dataset, get_main_dataset, red_shadow }
