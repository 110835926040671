<script>
import { Line } from 'vue-chartjs'
import { get_options } from '@/components/Chart_properties'

export default {
  extends: Line,
  props: {
    'content': {type: Object, required: true},
    'data': {type: Object, required: true},
    'period': {type: Number, required: true},
  },
  data () {
    return {
      options: get_options(this.data, this.content, this.period)
    }
  },
  watch: {
    content: function (new_content, unused_old_content) {
      if (this._chart) this._chart.destroy()
      this.renderChart(this.content, get_options(this.data, new_content, this.period))
    }
  }
}
</script>

<style>
canvas {
  width: 100% !important;
}
</style>
