<template lang="pug">
div
  b-modal(ref="data_modal_ref" modal-class="own_modal data_modal"
          :no-auto-focus="true" :no-enforce-focus="true")
    //- HEADER
    template(slot="modal-header")
      div.content(v-bind:style="{'background-color': host_color}")
        div.inner.pb-3
          div.title {{ data.name }}
        div.spinner
          clip-loader(:loading="loading" size="40px")
        //- Entry value
        div.lastentry(v-if="!loading")
          div.value(v-bind:class="on_alarm ? 'alarm' : ''")
            switches(v-if="is_bool" v-model="switch_value"
                     theme="custom" color="white" type-bold="true")
            template(v-if="!is_bool")
              span(v-if="is_readonly" @click="show_readonly_help = !show_readonly_help")
                | {{ related_entry.value }}{{ data.unit }}
              b-button-group.value_edit(v-if="!is_readonly"
                                        v-bind:class="error.length ? 'invalid' : ''")
                b-input.text-right(v-model="edited_value")
                b-button.first(variant="outline-success" @click="set_value(-1)"
                               v-bind:class="minus_disabled ? 'disabled' : ''")
                  icon(name="minus" scale=1)
                b-button(variant="outline-success" @click="set_value(1)"
                         v-bind:class="plus_disabled ? 'disabled' : ''")
                  icon(name="plus" scale=1)
          div.date {{ get_date(related_entry.pub_date) }}
    //- CONTENT
    div.slider(v-if="!is_bool" v-bind:style="{'background-color': host_color}")
      vue-slider(v-show="!on_alarm" ref="slider_simple" v-bind="slider_simple"
                 v-model="edited_value" :real-time="true")
      vue-slider(v-show="on_alarm" ref="slider_range" v-bind="slider_range"
                 v-model="value_range" :real-time="true")
      //- div {{ edited_value }}
      div.row.value_limit
        div.col.label.left(v-show="show_limit_min") Min
        div.col.label.text-right(v-show="show_limit_max") Max
      div.row.value_limit
        div.col.left(v-show="show_limit_min") {{ value_min }}{{ data.unit }}
        div.col.text-right(v-show="show_limit_max") {{ value_max }}{{ data.unit }}
    //- Error
    b-button.error(v-if="error.length" variant="danger" size="lg")
      icon(name="exclamation-triangle" scale="1.0")
      span.pl-3(v-html="error")
    //- Readonly
    b-button.readonly_help(v-if="show_readonly_help" variant="warning" size="lg"
                           @click="show_readonly_help = !show_readonly_help")
      icon(name="lock" scale="1.0")
      span.pl-3 Data can't be edited
      div.message since it's configured as read-only. It may be a sensor report
    //- Alarm
    b-button.alarm(v-if="on_alarm" variant="danger" size="lg")
      icon(name="bell" scale="1.0")
      span.pl-3 {{ alarm_message }}
    //- Pending
    b-button.pending(v-if="data.pending" variant="warning" size="lg"
                     @click="show_pending_help = !show_pending_help")
      icon(name="plug" scale="1.0")
      span.pl-3 Pending state since {{ get_date(related_entry.pub_date) }}
      div.message(v-if="show_pending_help")
        | The last value has not been transmitted correctly to the remote host because
        | it's not accessible.
    //- Links : charts, settings
    b-button-group.links
      b-button(variant="primary" size="lg" @click="to_chart()"
               v-bind:class="charts_disabled ? 'disabled' : ''")
        template(v-if="!charts_disabled")
          icon(name="chart-area" scale="1.0")
          span.pl-3 Charts
        div.message(v-if="charts_disabled")
          | Charts are disabled : value type is string or data record disabled
      b-button(variant="warning" size="lg" @click="open_settings_page()")
        icon(name="cogs" scale="1.0")
        span.pl-3 Settings
      //- b-button(variant="warning" size="lg" :href="data_admin_url" target="_blank")
        icon(name="cogs" scale="1.0")
        span.pl-3 Settings

    //- FOOTER
    template(v-slot:modal-footer)
      b-button-group
        b-button.left_radius(@click="hide()" :variant="!no_submit ? 'danger' : 'success'" size="lg"
                 :class="is_readonly ? 'right_radius' : ''") {{ no_submit ? 'Close' : 'Cancel' }}
        b-button.submit.right_radius(v-if="!is_readonly" @click="submit" size="lg"
                        :variant="no_submit ? 'warning' : 'success' "
                        v-bind:class="no_submit ? 'disabled' : '' ") Apply
</template>

<script>
import 'vue-awesome/icons/bell'
import 'vue-awesome/icons/plug'
import 'vue-awesome/icons/plus'
import 'vue-awesome/icons/minus'
import 'vue-awesome/icons/chart-area'
import 'vue-awesome/icons/cogs'
import 'vue-awesome/icons/lock'
import 'vue-awesome/icons/exclamation-triangle'
import Icon from 'vue-awesome/components/Icon'
import vueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { mapGetters } from 'vuex'
import chartinstance from '@/components/Chart_instance.vue'
import { get_element_by_id, get_host_color } from '@/utils'
import Switches from 'vue-switches'
import CONF from '@/conf'

var moment = require('moment')

export default {
  name: 'Data_modal',
  components: {
    Icon,
    vueSlider,
    ClipLoader,
    Switches,
    chartinstance
  },

  data () {
    return {
      loading: false,
      error: false,
      no_submit: true,
      modal: null,
      data: null,
      value_min: null,
      value_max: null,
      value_step: null,
      // value_error: null,
      related_entry: null,
      edited_value: null,
      switch_value: null,
      value_range: [],
      plus_disabled: null,
      minus_disabled: null,
      slider_value: 50,
      slider_simple: {
        tooltip: 'none',
        min: 0,
        max: 100
      },
      slider_range: {
        disabled: true,
        tooltip: 'always',
        tooltipDir: 'bottom',
        tooltipStyle: { 'backgroundColor': '#f55', 'borderColor': '#f00' },
        processStyle: { 'backgroundColor': '#f55' }
      },
      data_admin_url: null,
      data_chart_url: null,
      show_readonly_help: true,
      show_pending_help: true,
      show_limit_min: true,
      show_limit_max: true
    }
  },

  computed: {
    ...mapGetters({
      lastentry_map: 'get_lastentry_map',
      data_map: 'get_data_map',
      host_map: 'get_host_map'
    }),

    is_bool: function () { return (this.data.entry_type === 'BO') },
    is_readonly: function () { return (this.data.mode === 'RO') },
    host_color: function () { return get_host_color(this.host_map, this.data.host) },

    on_alarm () {
      if (this.edited_value !== this.related_entry.value) return 0
      if (parseFloat(this.edited_value) !== parseFloat(this.related_entry.value)) return 0
      if (!this.is_readonly && this.related_entry.direction === 'TR') return 0
      if (this.related_entry.value < this.value_min) return -1
      if (this.related_entry.value > this.value_max) return 1
      return 0
    },

    alarm_message () {
      let alarm_entry = null
      if (this.data.alarm_data.length) {
        alarm_entry = get_element_by_id(this.lastentry_map, this.data.alarm_data[0])
      }
      let alarm_message = `Alarm ${(this.on_alarm === -1) ? 'low' : 'high'}`
      if (alarm_entry) alarm_message += ` since ${this.get_date(alarm_entry.pub_date)}`
      return alarm_message
    },

    charts_disabled () {
      if (this.data.entry_creation === 'EP') return true
      if (this.data.entry_type === 'ST') return true
      return false
    }
  },

  watch: {
    switch_value: function (value) {
      if (this.is_readonly) {
        if (value !== this.related_entry.value) {
          this.show_readonly_help = !this.show_readonly_help
          setTimeout(() => { this.switch_value = this.related_entry.value }, 50)
        }
        this.no_submit = true
        return
      }
      this.edited_value = value
    },

    edited_value: function (value) {
      let update = () => {
        this.error = ''
        // this.value_error = null
        this.plus_disabled = false
        this.minus_disabled = false
        if (isNaN(this.edited_value) || (this.edited_value === '')) {
          this.error = 'Value is not a valid number'
          return
        }
        this.slider_simple.processStyle = { 'backgroundColor': '#f55' }
        this.edited_value = parseFloat(this.edited_value, 10)
        if (this.edited_value >= this.value_max) this.plus_disabled = true
        if (this.edited_value > this.value_max) {
          this.error = `Value to high (max ${this.value_max + this.data.unit || ''})`
          this.slider_simple.max = this.edited_value
          return
        }
        if (this.edited_value <= this.value_min) this.minus_disabled = true
        if (this.edited_value < this.value_min) {
          this.error = `Value to low (min ${this.value_min + this.data.unit || ''})`
          this.slider_simple.min = this.edited_value
          return
        }
        this.slider_simple.min = this.value_min
        this.slider_simple.max = this.value_max
        this.slider_simple.processStyle = { 'backgroundColor': '#0f0' }
      }
      if (!this.is_bool && !this.is_readonly) update()
      let prec_value = this.is_bool ? Boolean(this.related_entry.value) : this.related_entry.value
      this.no_submit = ((value === prec_value && !this.data.always_save_entry) ||
                        this.error.length || this.loading)
    }
  },

  created () {
    this.reset = () => {
      this.data = {'name': null, 'entry_type': 'IT', 'pending': false}
      this.value_min = -32000
      this.value_max = 32000
      this.value_step = 1
      this.related_entry = {'value': 0, 'edited_value': 0}
      // this.edited_value = 0
      this.value_range = [null, null]
      this.error = ''
      this.plus_disabled = false
      this.minus_disabled = false
      this.loading = false
    }
    this.reset()
  },

  mounted () {
    this.modal = this.$refs['data_modal_ref']
  },

  methods: {
    open_settings_page: function () {
      let win = window.open(this.data_admin_url, '_blank')
      win.focus()
    },
    show: function (data_pk) {
      this.reset()
      this.data = get_element_by_id(this.data_map, data_pk)
      this.value_min = this.data.value_min
      this.value_max = this.data.value_max
      this.value_step = this.data.value_step
      this.related_entry = get_element_by_id(this.lastentry_map, data_pk) || this.related_entry
      this.edited_value = this.related_entry.value || 0
      this.data_admin_url = `${CONF.ROOT_URL}admin/datas/data/${this.data.id}/change/`
      this.data_chart_url = `/charts/${this.data.id}`
      this.show_readonly_help = false
      this.show_pending_help = false
      this.show_limit_min = true
      this.show_limit_max = true

      if (this.is_bool) {
        this.edited_value = Boolean(this.edited_value)
        this.switch_value = this.edited_value
      } else {
        let slider_init = () => {
          let slider = this.on_alarm ? this.slider_range : this.slider_simple
          slider.processStyle = { 'backgroundColor': '#f55' }
          slider.min = this.value_min
          // TODO: what's wrong with max ?????
          slider.max = this.value_max - 1
          slider.interval = this.value_step
          if (!this.on_alarm) slider.disabled = this.is_readonly
          if (this.on_alarm === -1) {
            this.show_limit_min = false
            slider.min = this.related_entry.value
            this.value_range = [ this.related_entry.value, this.value_min ]
            return
          }
          if (this.on_alarm === 1) {
            this.show_limit_max = false
            slider.max = this.related_entry.value
            this.value_range = [ this.value_max, this.related_entry.value ]
            return
          }
          slider.processStyle = { 'backgroundColor': '#0f0' }
        }
        // Wait for slider ready
        setTimeout(slider_init, 100)
      }
      this.modal.show()
    },

    hide: function () {
      this.modal.hide()
    },

    get_date (timestamp) {
      return moment(timestamp).format('ddd DD/MM, H:mm')
    },

    to_chart: function () {
      this.hide()
      setTimeout(() => {
        this.$router.push(`/charts/${this.data.id}`)
      }, 100)
    },

    set_value: function (increase) {
      this.edited_value = parseFloat(this.edited_value, 10)
      let new_value = this.edited_value + (this.value_step * increase)
      if (increase < 0 & this.minus_disabled) return
      if (increase > 0 & this.plus_disabled) return
      this.edited_value = new_value
    },

    submit: function () {
      if (this.error.length) return
      this.error = ''
      let old_value = this.related_entry.value
      let value = this.edited_value
      if (!this.data.always_save_entry) {
        if (parseFloat(value) === parseFloat(old_value) || value === old_value) {
          this.hide()
          return
        }
      }
      this.loading = true

      let request = this.$http.post(
        CONF.ENTRY_URL, { id: this.data.id, value: value }, CONF.REQUEST_OPTIONS)
      request.then(
        unused_response => { this.hide() },
        response => {
          console.log(`Error POST request: ${response.status} ${response.body.text}`)
          this.$toaster['error'](response.body.text)
          this.loading = false
        }
      )
    }
  },
}
</script>

<style lang="scss">
@import '../assets/colors';
@import '../assets/mixins';

.data_modal {
  .modal-body,
  .modal-footer {

    > .pending,
    > .readonly_help {
      font-style: italic;
    }

    > .pending .message,
    > .readonly_help .message,
    .links .message  {
      font-family: 'OpenSans', Helvetica, Arial, sans-serif;
      font-size: .9rem;
      font-style: normal;
      white-space: normal;
    }

    .value_limit {
      color: $white;
      font-size: 1.2rem;
      padding: 0 5px;

      .label {
        font-size: .9rem;
      }
    }

    .slider {
      padding: 1rem;

      .vue-slider {
        height: .25rem !important;

        // .vue-slider-dot {
        //   box-shadow: 0 0 1px 1px $black_shadow !important;
        //   height: 2rem !important;
        //   top: -.8rem !important;
        //   width: 2rem !important;
        // }
      }

      // .vue-slider-dot-handle-disabled {
      //   background-color: darken($red, 20) !important;
      //   box-shadow: 0 0 1px 1px $black_shadow_light !important;
      // }
    }
  }

  .modal-header {
    .content {
      min-height: 64px;

      .inner {
        background-color: transparent !important;
        max-width: 55%;
        width: auto;

        .title {
          color: $white !important;
        }
      }

      .lastentry {
        color: $white;
        padding-right: 1.3rem;
        padding-top: .6rem;
        text-align: right;

        .vue-switcher {
          margin: 10px 0 -8px;

          div {
            height: 35px;
            width: 76px;

            &::after {
              height: 27px;
              margin-left: -32px;
              width: 27px;
            }
          }
        }
        .vue-switcher--unchecked {
          div {
            &::after {
              left: 38px;
            }
          }
        }
        .form-control {
          height: inherit;
        }
        .value {
          span {
            font-size: 3rem;
          }
        }
        .date {
          padding-top: 1px;
        }
      }

      .spinner {
        background-color: transparent !important;
        position: absolute;
        right: 8px;
        top: 8px;
        .v-clip {
          border-color: lighten($white, 20) lighten($white, 30)  transparent !important;
        }
      }

      .value_edit {
        padding-bottom: .1rem;
        padding-top: .4rem;

        &.invalid input {
          background-color: $red_shadow_light !important;
        }

        input {
          background-color: inherit;
          border-radius: .15rem 0 0 .15rem;
          color: $white;
          font-size: 3rem;
          line-height: inherit;
          max-width: 120px;
          padding: .1rem .5rem;

          &:focus {
            border-color: $white;
          }

          &:hover {
            background-color: $white_shadow_light;
          }

          &:not(:focus) {
            border-color: $white_shadow;
          }
        }

        button {
          background-color: inherit;
          // border-color: $form_bordercolor;
          border-color: $white_shadow;
          color: $white;
          line-height: 0;
          padding: .4rem 1.25rem;

          &:hover {
            background-color: $white_shadow_light;
          }

          &.first {
            border-left: 0;
            border-right: 0;
          }
          // }
        }
      }
    }
  }
  .vue-slider-wrap {
    padding-top: 25px !important;
  }
}
</style>
