<template lang="pug">
div.chart_container(v-if="data" v-bind:style="{'border-color': data.color }")
  div.error(v-show="error.length") {{ error }}
  div.header.row(v-if="data" v-bind:style="{'background-color': data.color }")
    div.data_name.col.pl-2.pr-2
      span(@click="show_data_modal(data.id)") {{ data.shortname }}
    b-form-select.col(v-if="show_height_form" :options="height_map" v-model="height" 
                      @input="height_user_change()")
    b-form-select.col(:options="period_map" v-model="period")
    div.value.col(@click="show_data_modal(data.id)")
      div.right(v-if="lastentry")
        data-widget(:data="data" :value="lastentry.value" size="small")
        div.value_pubdate {{ value_pubdate(lastentry) }}
    div.remove.col(v-if="landing" @click="show_chart_remove_modal(chart_pk)")
      icon(name="times")
  div.chart(v-bind:class="loading || error ? 'opacify' : ''")
    chartline(v-if="data && chart_content" :data="data" :content="chart_content" :period="period" 
              :styles="chart_styles")
    div.average.right
      span(v-if="min != null") Min:{{min}}{{data.unit}} / 
      span(v-if="max != null") Max:{{max}}{{data.unit}} / 
      span(v-if="average") Average:{{average}}{{data.unit}}
    div.chartzoom
      b-button.more(variant="success" size="lg" @click="set_period(1)"
              v-bind:class="is_disabled(1) ? 'disabled' : ''")
        icon(name="plus")      
      b-button.less(variant="success" size="lg" @click="set_period(-1)"
              v-bind:class="is_disabled(-1) ? 'disabled' : ''")
        icon(name="minus")
  div.spinner(v-if="loading")
    clip-loader(:loading="loading" size="50px")
</template>

<script>
import 'vue-awesome/icons/plus'
import 'vue-awesome/icons/minus'
import 'vue-awesome/icons/times'
import { mapGetters } from 'vuex'
import debounce from 'debounce'
import bus from '@/services/bus'
import CONF from '@/conf'
import Icon from 'vue-awesome/components/Icon'
import chartline from '@/components/Chart_line.vue'
import { get_dataset, get_main_dataset, red_shadow } from '@/components/Chart_properties'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { get_element_by_id, get_index_by_id } from '@/utils'
import Data_widget from '@/components/Data_widget'
var moment = require('moment')

const HEIGHT_SMALL = 130

export default ({
  name: 'Chart_instance',
  components: {
    Icon,
    chartline,
    ClipLoader,
    'data-widget': Data_widget
  },
  props: {
    'data_pk': {type: Number, required: true},
    'chart_pk': {type: Number, default: null},
    'landing': {type: Boolean, default: false},
    'prop_height': {type: Number, default: HEIGHT_SMALL},
  },
  data () {
    return {
      alarm_lastentry: null,
      data: null,
      datalist_form: [],
      chartline_map: {},
      chart_content: {datasets: []},
      error: '',
      height: 130,
      host: null,
      lastentry: null,
      loading: true,
      netstatus_data: null,
      netstatus_lastentry: null,
      period: 1,
      form_size: '400px',
      average: null,
      min: null,
      max: null,
      period_map: [
        { text: 'Day', id: 1, value: 1 },
        { text: '3 days', id: 3, value: 3 },
        { text: 'Week', id: 7, value: 7 },
        { text: '2 weeks', id: 14, value: 14 },
        { text: 'Month', id: 31, value: 31 },
        { text: '2 months', id: 62, value: 62 }
      ],
      height_map: [
        { text: 'Small', value: HEIGHT_SMALL },
        { text: 'Medium', value: 280 },
        { text: 'Big', value: 500 }
      ]
    }
  },

  computed: {
    ...mapGetters({
      data_map: 'get_data_map',
      entry_map: 'get_entry_map',
      lastentry_map: 'get_lastentry_map',
      host_map: 'get_host_map'
    }),
    period_text () {
      return get_element_by_id(this.period_map, this.period).text
    },
    chart_styles () {
      return {
        height: `${this.height}px`
      }
    },
    show_height_form () {
      if (!this.data || !this.landing) return false
      if (this.data.entry_type === 'BO') return false
      return true
    }
  },

  watch: {
    loading: function (loading, unused) {
      if (!loading) {
        bus.$emit(`state/chart/${this.data_pk}`, 'ready')
        this.update_chart(this.data)
        return
      }
      bus.$emit(`state/chart/${this.data_pk}`, 'loading')
    },
    period: function () { this.update_chart(this.data) },
    data_pk: function (newdata_pk, olddata_pk) { this.fetch_data(newdata_pk, olddata_pk) }
  },

  mounted () {
    this.get_chart_height = debounce(this.get_chart_height, 100)
    this.height_user_change = debounce(this.height_user_change, 5000)
    window.addEventListener('resize', this.get_chart_height)
    if (this.data_pk == null) return
    this.fetch_data(this.data_pk, null)
  },

  beforeDestroy () {
    bus.$off('socket/state')
    if (!this.data) return
    this.reset_listeners()
  },

  methods: {
    height_user_change: function () {
      console.log('Height user change')
      if (!this.landing || !this.height) return
      if (this.chart_pk == null) return
      let post_content = { chart_pk: this.chart_pk, height: this.height }
      let submit = this.$http.post(CONF.CHART_HEIGHT_URL, post_content, CONF.REQUEST_OPTIONS)
      submit.then(
        unused_response => {},
        response => {
          console.error(`Error POST request: ${response.status} ${response.body.text}`)
          this.$toaster['error'](response.body.text)
        }
      )
    },
    show_data_modal: function (data_pk) {
      bus.$emit('show_data_modal', data_pk)
    },
    show_chart_remove_modal: function (unused_chart_pk) {
      bus.$emit('show_chart_remove_modal', {pk: this.chart_pk, name: this.data.name})
    },
    value_pubdate: function (unused_data) {
      if (!this.lastentry) { return 'None' }
      return `${moment(this.lastentry.pub_date).format('ddd DD/MM, H:mm')}`
    },
    reset_listeners: function (data_pk) {
      if (data_pk == null) { return }
      bus.$off(`update/entry_map/${data_pk}`)
      bus.$off(`error/entry_map/${data_pk}`)
      bus.$off(`update/data/${data_pk}`)
      bus.$off(`update/lastentry/${data_pk}`)
      if (this.data != null) {
        bus.$off(`update/host/${this.data.host}`)
        bus.$off(`update/lastentry/${this.host.netstatus_data}`)
        if (this.data.alarm_data.length) {
          bus.$off(`update/lastentry/${this.data.alarm_data[0]}`)
        }
      }
    },
    is_disabled: function (increment) {
      if (this.loading) { return true }
      if (increment === 1 && this.period === 62) { return true }
      if (increment === -1 && this.period === 1) { return true }
    },
    get_host: function (data) {
      let host = get_element_by_id(this.host_map, data.host)
      if (host == null) { this.$socket.send('host_map') }
      bus.$on(`update/host/${data.host}`, (unused_response) => {
        this.host = get_element_by_id(this.host_map, data.host)
        this.data.color = this.host.color
        if (this.host.power_mode === 'DS') {
          this.loading = false
          return
        }
        // console.log(`get_host : host ${this.host.color} - lastentry ${this.lastentry}`)
        if (this.host.netstatus_data == null) {
          if (!this.data.alarm_data.length && this.lastentry) this.loading = false
          // this.loading = false
          return
        }
        this.netstatus_lastentry = this.get_netstatus_lastentry(this.host)
      })
      return host
    },
    get_netstatus_lastentry: function (host) {
      if (this.host.power_mode === 'DS') return {pub_date: 0, value: true}
      let netstatus_lastentry = get_element_by_id(this.lastentry_map, host.netstatus_data)
      if (!netstatus_lastentry) { this.$socket.send('lastentry', this.host.netstatus_data) }
      if (!this.data.alarm_data.length) this.loading = false
      bus.$on(`update/lastentry/${host.netstatus_data}`, (response) => {
        if (response === 'empty') return
        this.netstatus_lastentry = get_element_by_id(this.lastentry_map, this.host.netstatus_data)
      })
      return netstatus_lastentry
    },
    get_alarm_lastentry: function (data_pk) {
      let alarm_lastentry = get_element_by_id(this.lastentry_map, data_pk)
      if (!alarm_lastentry) { this.$socket.send('lastentry', data_pk) }
      bus.$on(`update/lastentry/${data_pk}`, (response) => {
        if (this.loading) { this.loading = false }
        if (response === 'empty') return
        this.alarm_lastentry = get_element_by_id(this.lastentry_map, data_pk)
      })
      return alarm_lastentry
    },
    get_lastentry: function (data_pk) {
      let lastentry = get_element_by_id(this.lastentry_map, data_pk)
      if (!lastentry) { this.$socket.send('lastentry', data_pk) }
      bus.$on(`update/lastentry/${data_pk}`, (response) => {
        if (this.loading && this.data.alarm_data.length) {
          if (data_pk === this.data.alarm_data[0]) this.loading = false
        }
        if (response === 'empty') return
        this.lastentry = get_element_by_id(this.lastentry_map, data_pk)
        // console.log(`get_lastentry : host ${this.host} - lastentry ${this.lastentry}`)
      })
      return lastentry
    },
    get_dataentry_map: function (data_pk, period_start) {
      let dataentry_map = []
      for (let dataentry of this.entry_map) {
        if (dataentry.id !== data_pk) continue
        for (let entry of dataentry.entries) {
          if (entry.pub_date < period_start) continue
          dataentry_map.push(entry)
        }
        break
      }
      return dataentry_map
    },
    get_dataentry_start: function (data_pk) {
      for (let dataentry of this.entry_map) {
        if (dataentry.id === data_pk) return dataentry.start
      }
    },
    set_period: function (increment) {
      if (this.is_disabled(increment)) { return }
      let index = get_index_by_id(this.period_map, this.period) + increment
      if (index > this.period_map.length || index < 0) index = 0
      this.period = this.period_map[index].id
    },
    get_main_chartmap: function (entry_map) {
      let time_now = new Date().getTime()
      let period_start = time_now - (this.period * 86400000)
      let chart_map = []
      let first_entry = null
      if (entry_map.length) {
        for (let entry of entry_map) {
          chart_map.push({ x: new Date(entry.pub_date), y: entry.value })
          // Boolean data specific
          if (this.data.entry_type === 'BO') {
            chart_map.push({ x: new Date(entry.pub_date + 1), y: !entry.value })
          }
          first_entry = entry
        }
        if (first_entry != null) {
          // Add point to the beginning of the chart (left)
          if (this.data.entry_type === 'BO') {
            chart_map.push({ x: new Date(period_start + 1), y: !first_entry.value })
          }
          chart_map.push({ x: new Date(period_start + 2), y: first_entry.value })
        }
      } else {
        // No entries found : cover all chart period with last entry value
        chart_map.push({ x: new Date(period_start + 1), y: this.lastentry.value })
      }
      // Add point to the end of the chart (right)
      chart_map.splice(0, 0, { x: new Date(time_now - 1), y: this.lastentry.value })
      return chart_map
    },
    get_secondary_chartmap: function (data_pk, lastvalue, inverted) {
      let time_now = new Date().getTime()
      let period_start = time_now - (this.period * 86400000)
      let entry_map = this.get_dataentry_map(data_pk, period_start)
      let chart_map = []
      // value was high before the start of the period : cover all chart as high
      if (!entry_map.length && lastvalue) {
        chart_map.push({ x: new Date(period_start + 1), y: !lastvalue })
        chart_map.push({ x: new Date(period_start + 2), y: lastvalue })
        chart_map.push({ x: new Date(time_now - 2), y: lastvalue })
        chart_map.push({ x: new Date(time_now - 1), y: !lastvalue })
      } else {
        let first_entry = null
        for (let entry of entry_map) {
          let value = (inverted) ? !entry.value : entry.value
          chart_map.push({ x: new Date(entry.pub_date - 1), y: value })
          chart_map.push({ x: new Date(entry.pub_date), y: !value })
          first_entry = entry
        }
        // Add point to the beginning of the chart (left)
        if (first_entry) {
          let value = (inverted) ? first_entry.value : !first_entry.value
          chart_map.push({ x: new Date(period_start + 1), y: value })
        }
        // Add point to the end of the chart (right)
        chart_map.splice(0, 0, { x: new Date(time_now - 1), y: lastvalue })
      }
      return chart_map
    },
    update_chart: function (data) {
      // console.log('Data change -> update !')
      let store_start = this.get_dataentry_start(data.id)
      let period_start = new Date().getTime() - (this.period * 86400000)
      // Entries need to be updated
      if (!store_start || store_start >= period_start) {
        this.fetch_entries(data)
        return
      }
      this.loading = false
      // Add main data entries to chartjs content
      let main_entry_map = this.get_dataentry_map(this.data.id, period_start)
      if (main_entry_map.length > 1) {
        let last_pubdate = moment().unix()*1000;
        let value_total = 0
        let period_total = 0
        this.min = (data.entry_type != 'BO') ? 32000 : null
        this.max = (data.entry_type != 'BO') ? -32000 : null
        // // consider chart ending with value of the closest entry
        // console.log(`First value is ${main_entry_map[0].value}`)
        // let value_total = main_entry_map[0].value

        for (let entry of main_entry_map) {
          if (data.entry_type != 'BO' && entry.value < this.min) this.min = entry.value
          if (data.entry_type != 'BO' && entry.value > this.max) this.max = entry.value
          let period = last_pubdate - entry.pub_date
          value_total += entry.value * period
          period_total += period
          last_pubdate = entry.pub_date
        }
        // consider chart beginning with value of the closest entry
        let period = last_pubdate - period_start
        let begin_value = main_entry_map[main_entry_map.length - 1].value
        if (data.entry_type == 'BO') begin_value = !begin_value
        value_total += begin_value * period
        period_total += period
        this.average = (value_total / period_total).toFixed(2) || 0
      } else { 
        this.min = null
        this.max = null
        this.average = null
      }
      this.average = (data.entry_type == 'BO') ? `${(this.average*100).toFixed(0)}%` : this.average
      let main_chart_map = this.get_main_chartmap(main_entry_map)
      let chart_content = { datasets: [get_main_dataset(this.data, main_chart_map)] }
      // Add host netstatus entries to chartjs content, if any
      if (this.host.power_mode !== 'DS') {
        if (this.host.netstatus_data && this.netstatus_lastentry) {
          let host_chart_map = this.get_secondary_chartmap(
            this.host.netstatus_data, !this.netstatus_lastentry.value, true)
          let host_dataset = get_dataset(host_chart_map)
          host_dataset.label = 'Host offline'
          chart_content.datasets.push(host_dataset)
        }
      }
      // Add ALARM entries to chartjs content, if any
      if (this.data.alarm_data.length && this.alarm_lastentry) {
        let alarm_chart_map = this.get_secondary_chartmap(
          this.data.alarm_data[0], this.alarm_lastentry.value, false)
        let alarm_dataset = get_dataset(alarm_chart_map)
        alarm_dataset.label = 'Alarm'
        alarm_dataset.backgroundColor = red_shadow
        chart_content.datasets.push(alarm_dataset)
      }
      // Finaly share data with chartjs
      // this.chart_content = null
      setTimeout(() => { this.chart_content = chart_content }, 10)
      // this.chart_content = chart_content
      bus.$emit(`state/chart_instance/${this.data.id}/ready`)
    },

    fetch_data: function (newdata_pk, olddata_pk) {
      this.loading = true
      this.reset_listeners(olddata_pk)
      bus.$on(`update/data/${newdata_pk}`, (unused_response) => {
        this.data = get_element_by_id(this.data_map, newdata_pk)
        if (!Object.prototype.hasOwnProperty.call(this.data, 'color')) this.data.color = '#000'
        // this.height = (this.data.entry_type === 'BO') ? 130 : this.height
        if (this.prop_height && (this.data.entry_type !== 'BO')) this.height = this.prop_height
        this.get_chart_height()
        this.lastentry = this.get_lastentry(newdata_pk)
        this.host = this.get_host(this.data)
        if (this.host != null) {
          this.data.color = this.host.color
          this.netstatus_lastentry = this.get_netstatus_lastentry(this.host)
        }
        if (this.data.alarm_data.length) {
          this.alarm_lastentry = this.get_alarm_lastentry(this.data.alarm_data[0])
        }
      })
      bus.$on(`update/entry_map/${newdata_pk}`, (unused_response) => {
        this.update_chart(this.data)
        this.error = ''
        this.loading = false
      })
      bus.$on(`error/entry_map/${newdata_pk}`, (response) => {
        this.error = response
        this.loading = false
      })
      this.data = get_element_by_id(this.data_map, newdata_pk)
      if (this.data == null) {
        this.$socket.send('data', newdata_pk)
        return
      }
      if (!Object.prototype.hasOwnProperty.call(this.data, 'color')) this.data.color = '#000'
      this.lastentry = this.get_lastentry(newdata_pk)
      if (this.prop_height && (this.data.entry_type !== 'BO')) this.height = this.prop_height
      else this.height = HEIGHT_SMALL
      this.get_chart_height()
      this.host = this.get_host(this.data)
      if (this.host == null) {
        return
      }
      this.data.color = this.host.color
      if (this.host.netstatus_data) {
        this.netstatus_lastentry = this.get_netstatus_lastentry(this.host)
        if (this.netstatus_lastentry == null) {
          return
        }
      }
      if (this.data.alarm_data.length) {
        this.alarm_lastentry = this.get_alarm_lastentry(this.data.alarm_data[0])
        if (this.alarm_lastentry == null) {
          return
        }
      }
      this.loading = false
      this.update_chart(this.data)
    },

    fetch_entries: function (data) {
      this.loading = true
      let fetched_entries = this.get_dataentry_map(data.id, 0)
      let end = new Date().getTime()
      if (fetched_entries && fetched_entries.length) {
        end = fetched_entries[0].pub_date
      }
      let start = end - (this.period * 86400000)
      let payload_data = JSON.stringify({ data_pk: data.id, start: start, end: end })
      this.$socket.send('data_entries', payload_data)
    },
    get_chart_height: function () {
      if (!this.data || this.landing) return
      this.height = (this.data.entry_type === 'BO') ? 
        HEIGHT_SMALL : parseInt(window.innerHeight - 90, 10)
    }
  },

})
</script>

<style lang="scss">
@import '../assets/colors';
@import '../assets/constants';
@import '../assets/mixins';

@media only screen and (min-width: 0) {
  .chart_container {
    border-radius: 0;
    border-width: 2px;
  }
}

.chart_container {
  position: relative;
  border-bottom: 0;
  border-style: solid;
  border-top: 0;
  // box-shadow: 0 0 .25rem $black_shadow_light;
  padding: 0;
  .error {
    font-size: 150%;
    font-style: italic;
    left: 40%;
    position: absolute;
    top: 40%;
  }
  .header {
    // height: 32px;
    margin: 0 !important;
    padding: .2rem 0;
    .data_name {
      span {
        cursor: pointer;
        font-size: 1.7rem;
        line-height: 3rem;
        white-space: nowrap;
      }
    }
    .value {
      cursor: pointer;
      font-size: 2rem;
      margin-left: 10px;
      margin-right: 5px;
      max-width: 75px;
      padding: 0;
      text-align: right;
      .pending_icon,
      .alarm_icon {
        top: -8px;
      }
      .pending_icon {
        right: -6px;
      }
      .alarm_icon {
        left: -6px;
      }
      .fake {
        display: none;
      }
      .value_pubdate {
        font-size: .7rem;
      }
      .vue-switcher {
        margin-left: 17px;
        padding-top: 5px;
      }
      label {
        margin-bottom: 0;
      }
    }
    .custom-select {
      background: $white url('data:image/svg+xml;charset=utf8,%3Csvg \
                            xmlns="http://www.w3.org/2000/svg" \
                            viewBox="0 0 4 5"%3E%3Cpath fill="%23BBB" d="M2 0L0 2h4zm0 5L0 3h4z" \
                            /%3E%3C/svg%3E') no-repeat right .5rem center;
      background-color: inherit;
      background-size: 8px 10px;
      border: 0;
      border-radius: 0;
      border-right: 1px solid $white_shadow_light;
      color: inherit;
      font-family: inherit;
      font-size: 1.4rem;
      height: 100% !important;
      max-width: 95px;
      padding-right: 20px;
      text-align: right;
      width: 90px;
    }
    .remove {
      cursor: pointer;
      max-width: 40px;
      padding: .1rem;
      text-align: right;
      .fa-icon {
        height: 28px;
        width: 28px;
      }
    }
  }
  .chart {
    padding-top: 2.5rem;
    padding-left: 4px;
    position: relative;
    div.average {
      color: $black_shadow;
      font-size: 150%;
      position: absolute;
      top: .25rem;
      right: 1%;
    }
    div.chartzoom {
      position: absolute;
      top: 2.5rem;
      right: 1rem;
      width: 36px;
      button {
        background-color: $white_shadow_light;
        border: 0;
        border-radius: 100px;
        color: $text_color3;
        cursor: pointer;
        height: 36px;
        // position: absolute;
        // right: .5rem;
        // top: 46%;
        width: 36px;
        // .fa-icon {
        //   right: 3px;
        // }
        &:hover {
          background-color: $white_shadow;
          color: $black;
        }
        &.disabled {
          cursor: default;
          background-color: $white_shadow_light2;
          color: lighten($text_color3, 20);
        }
        &.less {
          // top: 10%;
        }
        &.more {
          margin-bottom: .5rem;
        }
        .fa-icon {
          color: lighten($text_color3, 5);
          height: 16px;
          margin-bottom: .1rem;
          // position: absolute;
          // top: 6px;
          // vertical-align: middle;
          width: 16px;
        }
      }
    }
    // padding-top: 8px;
  }
  .spinner {
    left: 50%;
    position: absolute;
    top: 50%;
    .v-clip {
      border-color: lighten($black, 40) lighten($black, 50)  transparent !important;
    }
  }
}

.opacify {
  opacity: .5;
}

</style>
